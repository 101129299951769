export const primary_use_type = () => {
    const KEY = 'primary_use_type';
    const TYPE = 'includes';

    return {
        type: TYPE,
        key: KEY,
        values: [],
    };
};

export const compliance_status = () => {
    const KEY = 'normalized_status';
    const TYPE = 'includes';
    const COLORS = 'status';

    return {
        type: TYPE,
        key: KEY,
        values: [],
        colors: COLORS,
        ranges: ['in compliance', 'not submitted', 'exempt'], // 'city building', 'partial compliance'
        labels: {
            reportingPeriod: {},
            notReportingPeriod: {
                'not submitted': 'out of compliance',
            },
        },
        showCompliantOnly: false,
    };
};

export const site_eui = () => {
    const SLOTS = 5;
    const STEP = 40;
    const START = 0;
    const MIN = 0;
    const MAX = MIN + STEP * SLOTS;

    return {
        type: 'range',
        key: 'site_eui',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: false,
        exceedMax: true,
        values: [MIN, MAX],
        colors: 'goodToBad',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: true,
    };
};

export const water_intensity_total = () => {
    const SLOTS = 5;
    const STEP = 20;
    const START = 0;
    const MIN = 0;
    const MAX = START + STEP * SLOTS;

    return {
        type: 'range',
        key: 'water_intensity_total',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: false,
        exceedMax: true,
        values: [MIN, MAX],
        colors: 'goodToBad',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: true,
    };
};

export const energy_star_score = () => {
    const SLOTS = 5;
    const STEP = 20;
    const START = 0;
    const MIN = 0;
    const MAX = START + STEP * SLOTS;

    return {
        type: 'range',
        key: 'energy_star_score',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: false,
        exceedMax: false,
        values: [MIN, MAX],
        colors: 'badToGood',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: true,
    };
};

export const gross_floor_area = () => {
    const SLOTS = 5;
    const STEP = 20000;
    const START = 20000;
    const MIN = 20000;
    const MAX = START + STEP * SLOTS;

    return {
        type: 'range',
        key: 'gross_floor_area',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: true,
        exceedMax: true,
        values: [MIN, MAX],
        colors: 'neutral',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: false,
    };
};

export const year_built = () => {
    const SLOTS = 5;
    const STEP = 20;
    const START = 1920;
    const MIN = 1920;
    const MAX = START + STEP * SLOTS;

    return {
        type: 'range',
        key: 'year_built',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: true,
        exceedMax: true,
        values: [MIN, MAX],
        colors: 'neutral',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: false,
    };
};

export const utility_cost = () => {
    const SLOTS = 5;
    const STEP = 1.0;
    const START = 0.0;
    const MIN = 0.0;
    const MAX = START + STEP * SLOTS;

    return {
        type: 'range',
        key: 'utility_cost',
        slots: SLOTS,
        step: STEP,
        start: START,
        min: MIN,
        max: MAX,
        exceedMin: false,
        exceedMax: true,
        values: [MIN, MAX],
        colors: 'goodToBad',
        ranges: setRanges(START, MAX, STEP),
        showCompliantOnly: true,
    };
};

const filters = () => {
    const includedFilters = [
        primary_use_type(),
        site_eui(),
        energy_star_score(),
        gross_floor_area(),
        year_built(),
        water_intensity_total(),
        utility_cost(),
        compliance_status(),
    ];
    return includedFilters.reduce((obj, f) => {
        obj[f.key] = f;
        return obj;
    }, {});
};

function setRanges(start, max, step) {
    let num = start;
    const range = [];
    let init = false;
    while (num < max) {
        const low = init ? num + (max > 10 ? 1 : 0.01) : num;
        const high = num + step;
        init = true;
        range.push([low, high]);
        num += step;
    }
    return range;
}

export default filters;
