import { isEmpty, hasOwn } from 'utils/checkValue';
import { filter } from 'lodash';
const checkValue = (condition, value) => {
    switch (condition.type) {
        case 'range':
            if (
                (value[condition.key] !== 0 &&
                    value[condition.key] !== null &&
                    condition.values[0] > condition.min &&
                    value[condition.key] < condition.values[0]) ||
                (condition.values[1] < condition.max &&
                    value[condition.key] > condition.values[1]) ||
                ((value[condition.key] === 0 || value[condition.key] === null) &&
                    condition.values[0] > condition.min)
            )
                return false;
            break;
        default:
            if (
                !isEmpty(condition.values) &&
                !condition.values.includes(value[condition.key])
            )
                return false;
            break;
    }
    return true;
};

export default function filterData(data, filters, exclude = []) {
    const collection = filter(data, function (x) {
        for (let key in filters) {
            if (exclude.indexOf(key) > -1) continue;
            if (hasOwn(filters, key) && !checkValue(filters[key], x)) return false;
        }
        return true;
    });
    return collection;
}
