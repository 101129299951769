import { withStyles } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';

const TouchstoneProgressBar = withStyles((theme) => ({
    root: {
        height: 5,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[200],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary,
    },
}))(LinearProgress);

export default TouchstoneProgressBar;
