import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TouchstoneProgressBar from './TouchstoneProgressBar';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

function ProgressBarWithLabels(props) {
    const classes = useStyles();
    return (
        <Box display='flex' alignItems='center' className={classes.root}>
            <Box>
                <Typography variant='caption' color='textSecondary'>
                    0
                </Typography>
            </Box>
            <Box width='100%' ml={1} mr={1}>
                <TouchstoneProgressBar variant='determinate' {...props} />
            </Box>
            <Box>
                <Typography variant='caption' color='textSecondary'>
                    100
                </Typography>
            </Box>
        </Box>
    );
}

ProgressBarWithLabels.propTypes = {
    value: PropTypes.number.isRequired,
};

export default ProgressBarWithLabels;
