import { useSelector } from 'react-redux';
import { Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import { excluded } from 'utils/colors';
import { capitalize } from 'utils/formatter';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'block',
        marginBottom: theme.spacing(1),
        backgroundColor: '#ffffff',
    },
    element: {
        color: theme.palette.text.secondary,
        padding: theme.spacing(0.25, 0),
    },
    dot: {
        flex: '0 0 auto',
        borderRadius: '50%',
        width: 8,
        height: 8,
        marginRight: theme.spacing(1),
    },
}));

function BuildingsLegend() {
    const theme = useTheme();
    const classes = useStyles();
    const legend = useSelector((state) => state.app.legend);

    if (!legend) return null;

    return (
        <>
            <Typography component='p' variant='caption' className={classes.title}>
                <span dangerouslySetInnerHTML={{ __html: legend.title }} />
            </Typography>
            {legend.categories.map((elem, i) => (
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    className={classes.element}
                    key={i}
                >
                    <div
                        className={classes.dot}
                        style={{
                            backgroundColor: elem.color,
                        }}
                    />
                    {capitalize(elem.label)}
                </Grid>
            ))}
            {legend.showNotAvailable && (
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    className={classes.element}
                >
                    <div
                        className={classes.dot}
                        style={{
                            backgroundColor: excluded(theme),
                        }}
                    />
                    Not Available
                </Grid>
            )}
        </>
    );
}

export default BuildingsLegend;
