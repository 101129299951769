import { useDispatch, useSelector } from 'react-redux';
import { setCompareBuildings } from 'config/appSlice';
import PropTypes from 'prop-types';
import { Button, Paper, Typography, makeStyles } from '@material-ui/core';
import ProgressBarWithLabels from 'components/common/ProgressBarWithLabels';
import CompareEui from 'components/info/histograms/CompareEUI';
import { capitalize, numberFormat } from 'utils/formatter';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        minWidth: '225px',
        padding: theme.spacing(1),
    },
    energy: {
        padding: theme.spacing(1.5),
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(1.5),
        marginRight: 'auto',
        marginLeft: 'auto',
    },
}));

function BuildingInfo(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedYear = useSelector((state) => state.app.selectedYear);
    const buildings = useSelector((state) => state.app.compareBuildings);

    const { building_id, street, postal_code, year_built, city, parent_building_id } =
        props.info;

    const {
        building_name,
        energy_star_score,
        gross_floor_area,
        primary_use_type,
        national_median_site_eui,
        site_eui,
        normalized_status,
    } = props.info.parent ? props.info.parent : props.info;

    const handleAddToComparison = () => {
        const info = props.info.parent ? props.info.parent : props.info;
        dispatch(setCompareBuildings(info));
    };

    const hasBuilding = () => {
        const id = parent_building_id ? parent_building_id : building_id;
        const index = buildings.findIndex((b) => b.building_id === id);
        return index > -1;
    };

    const statusLabel = () => {
        let output;
        if (building_id.includes('CB') || building_id.includes('LIC')) {
            output = 'in compliance';
        } else {
            output = normalized_status;
        }
        return output;
    };

    return (
        <div className={classes.wrapper}>
            <Typography
                color='textSecondary'
                variant='subtitle2'
                component='h2'
                gutterBottom
            >
                {capitalize(primary_use_type)}
            </Typography>
            {building_name && (
                <Typography
                    color='textPrimary'
                    variant='caption'
                    component='p'
                    gutterBottom
                >
                    {building_name}
                </Typography>
            )}
            <Typography color='textPrimary' variant='caption' component='p' gutterBottom>
                {capitalize(street)} <br />
                {capitalize(city)}, {postal_code}
            </Typography>
            {parent_building_id && (
                <Typography variant='caption' component='p' gutterBottom>
                    Parent Property ID {parent_building_id}
                </Typography>
            )}
            <Typography variant='caption' component='p' gutterBottom>
                Building ID {building_id}
            </Typography>
            <Typography variant='caption' component='p' gutterBottom>
                {numberFormat(gross_floor_area)} ft<sup>2</sup>
            </Typography>

            {year_built > 0 && (
                <Typography variant='caption' component='p' gutterBottom>
                    Built in {year_built}
                </Typography>
            )}

            {normalized_status.toLowerCase() !== 'exempt' && (
                <Typography variant='caption' component='p' gutterBottom>
                    Benchmarking Status - {capitalize(statusLabel())}
                </Typography>
            )}

            {normalized_status.toLowerCase() === 'exempt' && (
                <Paper variant='outlined' className={classes.energy}>
                    <Typography color='textSecondary' variant='body1' component='h4'>
                        Building is Exempt
                    </Typography>
                    <Typography variant='h5' component='h5' gutterBottom>
                        {selectedYear}
                    </Typography>
                </Paper>
            )}

            {energy_star_score > 0 && normalized_status === 'in compliance' && (
                <Paper variant='outlined' className={classes.energy}>
                    <Typography color='textSecondary' variant='body1' component='h3'>
                        ENERGY STAR<sup>&reg;</sup> Score
                    </Typography>
                    <Typography variant='h5' component='h4' gutterBottom>
                        {energy_star_score}
                    </Typography>
                    <ProgressBarWithLabels value={energy_star_score} />
                </Paper>
            )}

            {site_eui > 0 && normalized_status === 'in compliance' && (
                <Paper variant='outlined' className={classes.energy}>
                    <Typography color='textSecondary' variant='body1' component='h3'>
                        Site EUI / National Median
                    </Typography>
                    <Typography variant='h5' component='h4'>
                        {site_eui} / {national_median_site_eui}
                    </Typography>
                    <Typography
                        color='textSecondary'
                        variant='caption'
                        component='p'
                        gutterBottom
                    >
                        kBTU/ft<sup>2</sup>
                    </Typography>
                    <CompareEui
                        site={site_eui}
                        nationalMedian={national_median_site_eui}
                    />
                </Paper>
            )}
            <Button
                size='small'
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={handleAddToComparison}
                disabled={normalized_status !== 'in compliance'}
            >
                {hasBuilding() ? 'Remove from' : 'Add to'} Comparison
            </Button>
        </div>
    );
}

BuildingInfo.propTypes = {
    info: PropTypes.object.isRequired,
};

export default BuildingInfo;
